import React, { useState, useEffect } from 'react';
import { FaVideo, FaNewspaper, FaBusinessTime, FaStar, FaHeart, FaMoneyBillAlt, FaPrayingHands, FaCar, FaMusic, FaRunning, FaMedkit, FaPlane, FaBriefcase, FaGlobe } from 'react-icons/fa';
import { useNavigate, Link } from 'react-router-dom';

const CategoryDropdown = () => {
    const navigate = useNavigate();
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    useEffect(() => {
        const token = localStorage.getItem('authTokenSitusNews');
        if (token) {
            setIsLoggedIn(true);
        }
    }, []);

    const handleLoginClick = () => {
        if (isLoggedIn) {
            localStorage.removeItem('authTokenSitusNews');
            localStorage.removeItem('authorId');
            setIsLoggedIn(false);
            navigate('/');
        } else {
            navigate('/login');
        }
    };

    const handleLogoutClick = () => {
        if (isLoggedIn) {
            localStorage.removeItem('token');
            localStorage.removeItem('authTokenSitusNews');
            localStorage.removeItem('authorId');
            setIsLoggedIn(false);
            navigate('/');
        } else {
            navigate('/login');
        }
    };

    return (
        <div style={styles.container}>
        <div style={styles.loginSection}>
            {isLoggedIn ? (
                <Link to="/index" style={styles.loginText}>
                    Dashboard
                </Link>
            ) : (
                <>
                    <span style={styles.loginText} onClick={handleLoginClick}>
                        Log In
                    </span>
                    <Link to="https://webmail.kartikalidya.com/" target="_blank" style={styles. emailSection2}>
                        Email
                    </Link>
                </>
            )}
        </div>
            <hr style={styles.divider} />
            <div style={styles.categoryList}>
                <div style={styles.categoryColumn}>
                    <Link to="/kategori/video" style={styles.navLink}>
                        <CategoryItem icon={<FaVideo />} text="Video" />
                    </Link>
                    <Link to="/kategori/news" style={styles.navLink}>
                        <CategoryItem icon={<FaNewspaper />} text="News" />
                    </Link>
                    <Link to="/kategori/bisnis" style={styles.navLink}>
                        <CategoryItem icon={<FaBusinessTime />} text="Bisnis" />
                    </Link>
                    <Link to="/kategori/seleb" style={styles.navLink}>
                        <CategoryItem icon={<FaStar />} text="Seleb" />
                    </Link>
                    <Link to="/kategori/karir" style={styles.navLink}>
                        <CategoryItem icon={<FaBriefcase />} text="Karir" />
                    </Link>
                    <Link to="/kategori/ekonomi" style={styles.navLink}>
                        <CategoryItem icon={<FaMoneyBillAlt />} text="Ekonomi" />
                    </Link>
                    <Link to="/kategori/religi" style={styles.navLink}>
                        <CategoryItem icon={<FaPrayingHands />} text="Religi" />
                    </Link>
                </div>
                <div style={styles.categoryColumn}>
                    <Link to="/kategori/otomotif" style={styles.navLink}>
                        <CategoryItem icon={<FaCar />} text="Otomotif" />
                    </Link>
                    <Link to="/kategori/musik" style={styles.navLink}>
                        <CategoryItem icon={<FaMusic />} text="Musik" />
                    </Link>
                    <Link to="/kategori/olahraga" style={styles.navLink}>
                        <CategoryItem icon={<FaRunning />} text="Olahraga" />
                    </Link>
                    <Link to="/kategori/kesehatan" style={styles.navLink}>
                        <CategoryItem icon={<FaMedkit />} text="Kesehatan" />
                    </Link>
                    <Link to="/kategori/travel" style={styles.navLink}>
                        <CategoryItem icon={<FaPlane />} text="Travel" />
                    </Link>
                    <Link to="/kategori/gaya-hidup" style={styles.navLink}>
                        <CategoryItem icon={<FaHeart />} text="Gaya Hidup" />
                    </Link>
                    <Link to="/kategori/internasional" style={styles.navLink}>
                        <CategoryItem icon={<FaGlobe />} text="Internasional" />
                    </Link>
                </div>
            </div>
            {isLoggedIn && (
            <div>
            <div style={styles.emailSection}>
                <Link to="https://webmail.kartikalidya.com/" target="_blank" style={styles.emailSection}>
                    Email
                </Link>
            </div>
            <div style={styles.logoutSection} onClick={handleLogoutClick}>
                <span style={styles.logoutText}>Log Out</span>
            </div>
        </div>
    )}
</div>
);
};

const CategoryItem = ({ icon, text }) => (
    <div style={styles.categoryItem}>
        <span style={styles.icon}>{icon}</span>
        <span style={styles.text}>{text}</span>
    </div>
);

const styles = {
    container: {
        padding: '20px',
        width: '250px',
        backgroundColor: '#fff',
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
    },
    loginSection: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '20px',
    },
    icon: {
        fontSize: '24px',
        marginRight: '10px',
        color: '#005694',
    },
    emailSection: {
        fontSize: '18px',
        fontWeight: 'bold',
        color: '#005694',
        textDecoration: 'none',
        paddingBottom: '10px',
        borderBottom: '1px solid #ccc', // Garis pemisah di bawah Email
        marginBottom: '10px',
    },
    emailSection2: {
        fontSize: '18px',
        fontWeight: 'bold',
        color: '#005694',
        marginRight: '45px',
        textDecoration: 'none',
    },
    loginText: {
        fontSize: '18px',
        fontWeight: 'bold',
        color: '#005694',
        textDecoration: 'none',
        cursor: 'pointer',
    },
    divider: {
        margin: '10px 0',
        border: 'none',
        borderTop: '1px solid #ccc',
    },
    categoryList: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    categoryColumn: {
        display: 'flex',
        flexDirection: 'column',
    },
    categoryItem: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '10px',
    },
    text: {
        marginLeft: '10px',
        fontSize: '16px',
    },
    logoutSection: {
        marginTop: '20px',
        cursor: 'pointer',
    },
    logoutText: {
        fontSize: '18px',
        fontWeight: 'bold',
        color: '#FF5733',
    },
    navLink: {
        textDecoration: 'none',
        color: 'inherit',
    },
};

export default CategoryDropdown;
