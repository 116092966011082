import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import configUrl from '../configUrl';

const styles = {
  container: {
    padding: ' 0px 10px',
    marginLeft: '-60px',
    textAlign: 'center',
    margin: '0 auto', // Center horizontally
    maxWidth: '1000px',
    boxSizing: 'border-box', // Include padding and border in the element's total width and height
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '-160px',
    justifyContent: 'center',
    marginBottom: '20px',
  },
  underline: {
    width: '6px',
    height: '24px',
    backgroundColor: '#005694',
    marginRight: '10px',
  },
  trendingTitle: {
    fontSize: '24px',
    fontWeight: 'bold',
  },
  articlesList: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    gap: '20px',
    marginLeft: '-30px',
  },
  card: {
    backgroundColor: '#fff',
    borderRadius: '8px',
    overflow: 'hidden',
    width: '100%',
    maxWidth: '250px',
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
    transition: 'transform 0.3s ease',
  },
  image: {
    width: '100%',
    height: '150px',
    objectFit: 'cover',
    borderBottom: '2px solid #e0e0e0',
  },
  cardContent: {
    padding: '10px',
  },
  sourceText: {
    fontSize: '14px',
    color: '#005694',
    fontWeight: 'bold',
    display: 'block',
    marginBottom: '5px',
  },
  titleLink: {
    textTransform: 'capitalize',
    fontSize: '16px',
    color: '#333',
    fontWeight: '500',
    textDecoration: 'none',
  },
};
// Mobile Styles
const mobileStyles = {
  container: {
    padding: ' 0px 10px',
    textAlign: 'center',
    margin: '0 auto', // Center horizontally
    maxWidth: '1000px',
    boxSizing: 'border-box', // Include padding and border in the element's total width and height
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '10px',
    marginBottom: '20px',
  },
  underline: {
    width: '6px',
    height: '24px',
    backgroundColor: '#005694',
    marginRight: '10px',
  },
  trendingTitle: {
    fontSize: '24px',
    fontWeight: 'bold',
  },
  articlesList: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    gap: '20px',
  },
  card: {
    backgroundColor: '#fff',
    borderRadius: '8px',
    overflow: 'hidden',
    width: '95%',
    maxWidth: '100%',
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
    transition: 'transform 0.3s ease',
  },
  image: {
    width: '100%',
    height: '150px',
    objectFit: 'cover',
    borderBottom: '2px solid #e0e0e0',
  },
  cardContent: {
    padding: '10px',
  },
  sourceText: {
    fontSize: '14px',
    color: '#005694',
    fontWeight: 'bold',
    display: 'block',
    marginBottom: '5px',
  },
  titleLink: {
    textTransform: 'capitalize',
    fontSize: '16px',
    color: '#333',
    fontWeight: '500',
    textDecoration: 'none',
  },
};

const TopTags = () => {
  const [articles, setArticles] = useState(() => {
    const savedArticles = localStorage.getItem('topTagArticles');
    return savedArticles ? JSON.parse(savedArticles) : [];
  });

  const [loading, setLoading] = useState(true); // State for loading
  const [error, setError] = useState(null); // State for error handling
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 480);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 480);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const appliedStyles = isMobile ? mobileStyles : styles;

  useEffect(() => {
    const fetchTopTagArticles = async () => {
      try {
        const cachedArticles = localStorage.getItem('topTagArticles');
        if (cachedArticles) {
          setArticles(JSON.parse(cachedArticles));
        } else {
          const response = await axios.get(`${configUrl.beBaseUrl}/api/articlestoptags`);
          setArticles(response.data);
          localStorage.setItem('topTagArticles', JSON.stringify(response.data));
        }
      } catch (error) {
        console.error('Error fetching top tag articles:', error);
        setError('Failed to fetch articles.'); // Set error message
      } finally {
        setLoading(false); // Set loading to false when done
      }
    };

    fetchTopTagArticles();
  }, []);

  return (
    <div>
      <div style={appliedStyles.container}>
        <div style={appliedStyles.titleContainer}>
          <div style={appliedStyles.underline}></div>
          <p style={appliedStyles.trendingTitle}>Trending Hari Ini</p>
        </div>
        {loading ? (
          <p>Loading...</p>
        ) : error ? (
          <p style={{ color: 'red' }}>{error}</p>
        ) : (
          <div className="top-tag-articles-list" style={appliedStyles.articlesList}>
            {articles.map(({ id, image_url, title, slug }) => (
              <div key={id} className="card-tag" style={appliedStyles.card}>
                <img
                  src={`${configUrl.beBaseUrl}${image_url}`}
                  alt={title}
                  style={appliedStyles.image}
                />
                <div className="card-content" style={appliedStyles.cardContent}>
                  <span style={appliedStyles.sourceText}>kartikalidya.com</span>
                  <Link to={`/articles/${id}/${slug}`} style={appliedStyles.titleLink}>
                    {title}
                  </Link>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default TopTags;
