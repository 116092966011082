import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../utils/AuthContext';
import CategoryDropdown from './CategoryDropdown';
import { FaSearch, FaBars, FaTimes } from 'react-icons/fa';

const Header = () => {
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth();
  const [tampilkanDropdown, setTampilkanDropdown] = useState(false);

  const toggleDropdown = () => {
    setTampilkanDropdown((prevState) => !prevState);
  };

  const goToSearchPage = () => {
    navigate('/search');
  };

  return (
    <div>
      <header style={styles.header}>
        <div style={styles.logoContainer}>
          <a href='https://www.kartikalidya.com'>
          <img src="/image/kartikanews-logo.png" alt="kartikanews-logo" style={styles.logoImage} />
          </a>
        </div>
        <div style={styles.iconsContainer}>
          <div style={{ ...styles.icon, ...styles.searchIcon }} onClick={goToSearchPage}>
            <FaSearch />
          </div>
          <div style={{ ...styles.icon, ...styles.hamburgerMenu }} onClick={toggleDropdown}>
            {tampilkanDropdown ? <FaTimes /> : <FaBars />}
          </div>
        </div>
        {tampilkanDropdown && (
          <div style={styles.dropdownContainer}>
            <CategoryDropdown />
          </div>
        )}
      </header>

      <nav style={styles.navbar}>
        <div style={styles.navContent}>
          <Link to="/" style={styles.navLink}>Beranda</Link>
          <Link to="/BeritaTerkini" style={styles.navLink}>Terkini</Link>
          <Link to="/PopularArticles" style={styles.navLink}>Terpopuler</Link>
          <Link to="/kategori/video" style={styles.navLink}>Video</Link>
          <Link to="/kategori/news" style={styles.navLink}>News</Link>
          <Link to="/kategori/bisnis" style={styles.navLink}>Bisnis</Link>
          <Link to="/kategori/seleb" style={styles.navLink}>Seleb</Link>
          <Link to="/kategori/karir" style={styles.navLink}>Karir</Link>
          <Link to="/kategori/ekonomi" style={styles.navLink}>Ekonomi</Link>
          <Link to="/kategori/religi" style={styles.navLink}>Religi</Link>
          <Link to="/kategori/otomotif" style={styles.navLink}>Otomotif</Link>
          <Link to="/kategori/kesehatan" style={styles.navLink}>Kesehatan</Link>
          <Link to="/kategori/musik" style={styles.navLink}>Musik</Link>
          {isAuthenticated && <Link to="/index" style={styles.navLink}>Dashboard</Link>}
        </div>
      </nav>
    </div>
  );
};

const styles = {
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: 'white',
    padding: '10px 20px',
    position: 'relative',
  },
  logoContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '10px',
  },
  logoImage: {
    maxHeight: '50px',
  },
  iconsContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '18px',
  },
  icon: {
    fontSize: '24px',
    color: '#005694',
    marginLeft: '20px',
    cursor: 'pointer',
  },
  searchIcon: {
    position: 'relative',
  },
  hamburgerMenu: {
    position: 'relative',
  },
  dropdownContainer: {
    position: 'absolute',
    right: '20px',
    top: '60px',
    zIndex: 1000,
  },
  navbar: {
    display: 'flex',
    overflowX: 'auto', // Enable horizontal scrolling
    whiteSpace: 'nowrap', // Prevent wrapping
    backgroundColor: '#005694',
    padding: '10px 0',
    marginTop: '10px',
  },
  navContent: {
    display: 'inline-flex', // Keep links in a row
  },
  navLink: {
    color: 'white',
    textDecoration: 'none',
    padding: '10px 30px',
    fontWeight: 'bold',
  },
};

export default Header;
