import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link, useLocation } from 'react-router-dom';
import configUrl from '../configUrl';
import Header from './Header';
import Footer from './Footer';

const BeritaTerkini = () => {
  const location = useLocation();
  const [articles, setArticles] = useState([]);
  const [categories, setCategories] = useState([]);
  const [error, setError] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 480);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 480);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const appliedStyles = isMobile ? mobileStyles : styles;

  // Fetch articles
  useEffect(() => {
    axios.get(`${configUrl.beBaseUrl}/api/articles`)
      .then(response => {
        const twoDaysAgo = new Date();
        twoDaysAgo.setDate(twoDaysAgo.getDate() - 60);
        let latestArticles = response.data.slice(0, 5);

        const recentArticles = latestArticles.filter(article => 
          new Date(article.created_at) >= twoDaysAgo
        ).sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

        setArticles(recentArticles);
        setError(null);
      })
      .catch(error => {
        console.error('Error fetching articles:', error);
        setError('Failed to fetch articles.');
      });
  }, []);

  // Fetch categories
  useEffect(() => {
    axios.get(`${configUrl.beBaseUrl}/api/categories`)
      .then(response => {
        setCategories(response.data);
        setError(null);
      })
      .catch(error => {
        console.error('Error fetching categories:', error);
        setError('Failed to fetch categories.');
      });
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();

    return `Published: ${day}-${month}-${year}`;
  };

  // Get category name based on category ID
  const getCategoryName = (categoryId) => {
    const category = categories.find(cat => cat.id === categoryId);
    return category ? category.name : 'Kategori Tidak Dikenal';
  };

  return (
    <div>
      {location.pathname !== '/' && <Header />}
      <div style={appliedStyles.container}>
        <div style={appliedStyles.titleContainer}>
          <div style={appliedStyles.underline}></div>
          <h3 style={appliedStyles.headerArTerkini}>TERKINI</h3>
        </div>
        {error && <p style={appliedStyles.error}>{error}</p>}
        <div style={appliedStyles.articleList}>
          {articles.map(article => (
            <div key={article.id} style={appliedStyles.popularArticleCard}>
              {/* Category Link */}
              <div style={appliedStyles.articleCategoryContainer}>
                <div style={appliedStyles.articleCategory}>
                  <Link to={`/kategori/${getCategoryName(article.category_id)}`} style={appliedStyles.articleCategory}>
                    {getCategoryName(article.category_id)}
                  </Link>
                </div>
                <img 
                  src={`${configUrl.beBaseUrl}${article.image_url}`} 
                  alt={article.title} 
                  style={appliedStyles.img}
                />
              </div>
              <div style={appliedStyles.cardArticleRight}>
                <Link to={`/articles/${article.id}/${encodeURIComponent(article.slug)}`} style={appliedStyles.articleLink}>
                  {article.title}
                </Link>
                <p style={appliedStyles.articleBody}>
                  {article.body.substring(0, 55)}...
                </p>
                <div style={appliedStyles.metaContainer}>
                  <span style={appliedStyles.articleDate}>
                    {formatDate(article.created_at)}
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      {location.pathname !== '/' && <Footer />}
    </div>
  );
};

// Styles for larger screens
const styles = {
  container: {
    padding: '0px 100px',
    margin: '0 auto',
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
  },
  underline: {
    width: '6px',
    height: '24px',
    backgroundColor: '#005694',
    marginRight: '10px',
  },
  headerArTerkini: {
    fontSize: '24px',
    fontWeight: 'bold',
  },
  articleList: {
    display: 'flex',
    flexDirection: 'column',
  },
  popularArticleCard: {
    display: 'flex',
    marginBottom: '10px',
    borderBottom: '1px solid #ddd',
    paddingBottom: '5px',
    flexWrap: 'wrap',
  },
  img: {
    width: '100%',
    maxWidth: '100px',
    height: '70px',
    objectFit: 'cover',
    marginBottom: '5px',
    marginRight: '10px',
  },
  articleContent: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  articleCategory: {
    fontSize: '12px',
    color: '#005694',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    marginBottom: '5px',
    textAlign: 'left',
    textDecoration: 'none',
  },
  cardArticleRight: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 0%',
    marginTop: '16px',
  },
  articleLink: {
    fontSize: '14px',
    fontWeight: 'bold',
    color: '#000',
    textDecoration: 'none',
    textAlign: 'left',
  },
  articleBody: {
    fontSize: '12px',
    color: '#333',
    margin: '5px 0',
    textAlign: 'left',
  },
  articleDate: {
    fontSize: '12px',
    color: '#666',
    textAlign: 'left',
  },
  metaContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  error: {
    color: 'red',
  },
};

// Mobile Styles
const mobileStyles = {
  container: {
    padding: '20px',
    marginTop: '-20px',
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
  },
  underline: {
    width: '6px',
    height: '24px',
    backgroundColor: '#005694',
    marginRight: '10px',
  },
  headerArTerkini: {
    fontSize: '24px',
    fontWeight: 'bold',
  },
  articleList: {
    display: 'flex',
    flexDirection: 'column',
  },
  popularArticleCard: {
    display: 'flex',
    marginBottom: '10px',
    borderBottom: '1px solid #ddd',
    paddingBottom: '5px',
    flexWrap: 'wrap',
  },
  img: {
    width: '100%',
    maxWidth: '100px',
    height: '70px',
    objectFit: 'cover',
    marginBottom: '5px',
    marginRight: '10px',
  },
  articleContent: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  articleCategory: {
    fontSize: '12px',
    color: '#005694',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    marginBottom: '5px',
    textAlign: 'left',
    textDecoration: 'none',
  },
  cardArticleRight: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 0%',
    marginTop: '16px',
  },
  articleLink: {
    fontSize: '14px',
    fontWeight: 'bold',
    color: '#000',
    textDecoration: 'none',
    textAlign: 'left',
  },
  articleBody: {
    fontSize: '12px',
    color: '#333',
    margin: '5px 0',
    textAlign: 'left',
  },
  articleDate: {
    fontSize: '12px',
    color: '#666',
    textAlign: 'left',
  },
  metaContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  error: {
    color: 'red',
  },
};

export default BeritaTerkini;
