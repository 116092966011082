import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FaHome, FaEdit, FaPlus, FaList, FaFolder, FaBell, FaCog, FaUser } from 'react-icons/fa';

const SidebarDashboard = () => {
    const location = useLocation();
    const [activeLink, setActiveLink] = useState(location.pathname);

    const handleLinkClick = (path) => {
        setActiveLink(path);
    };

    useEffect(() => {
        setActiveLink(location.pathname);
    }, [location]);

    return (
        <div style={styles.container}>
            <div style={styles.sidebarContainer}>
                <div style={styles.logoContainer}>
                    <img src="/logo512.png" alt="kartikanews-logo1" style={styles.logoImageSmall} />
                    <img src="/image/kartikanews-logo.png" alt="kartikanews-logo2" style={styles.logoImage} />
                </div>
                <ul style={styles.sidebarList}>
                    <li style={styles.listSidebar}>
                        <Link
                            to="/index"
                            style={activeLink === '/index' ? { ...styles.link, ...styles.linkActive } : styles.link}
                            onClick={() => handleLinkClick('/index')}
                        >
                            <FaHome style={styles.icon} /> Dashboard
                        </Link>
                    </li>
                    <li style={styles.listSidebar}>
                        <Link
                            to="/create-article"
                            style={activeLink === '/create-article' ? { ...styles.link, ...styles.linkActive } : styles.link}
                            onClick={() => handleLinkClick('/create-article')}
                        >
                            <FaPlus style={styles.icon} /> Create
                        </Link>
                    </li>
                    <li style={styles.listSidebar}>
                        <Link
                            to="/dashboard"
                            style={activeLink === '/dashboard' ? { ...styles.link, ...styles.linkActive } : styles.link}
                            onClick={() => handleLinkClick('/dashboard')}
                        >
                            <FaEdit style={styles.icon} /> Post
                        </Link>
                    </li>
                    <li style={styles.listSidebar}>
                        <Link
                            to="/articlelist"
                            style={activeLink === '/articlelist' ? { ...styles.link, ...styles.linkActive } : styles.link}
                            onClick={() => handleLinkClick('/articlelist')}
                        >
                            <FaList style={styles.icon} /> List
                        </Link>
                    </li>
                    <li style={styles.listSidebar}>
                        <Link
                            to="/category"
                            style={activeLink === '/category' ? { ...styles.link, ...styles.linkActive } : styles.link}
                            onClick={() => handleLinkClick('/category')}
                        >
                            <FaFolder style={styles.icon} /> Category
                        </Link>
                    </li>
                </ul>
            </div>
            <HeaderDashboard />
        </div>
    );
};

const HeaderDashboard = () => {
    const [showOptions, setShowOptions] = useState(false); // State untuk menampilkan opsi
    const toggleOptions = () => setShowOptions(!showOptions); // Fungsi untuk toggle opsi

    return (
        <div style={styles.headerContainer}>
            <div style={styles.searchContainer}>
                <input 
                    type="text" 
                    placeholder="search" 
                    style={styles.searchInput}
                />
            </div>
            <div style={styles.iconsContainer}>
                <FaBell style={styles.headerIcon} />
                <FaUser style={styles.headerIcon} onClick={toggleOptions} /> {/* Tambahkan onClick */}
                <span style={styles.adminText}>Halo admin!</span>
                {showOptions && ( // Tampilkan opsi jika showOptions true
                    <div style={styles.optionsContainer}>
                        <Link to="/" style={styles.optionLink}>Beranda</Link>
                        <Link to="/login" style={styles.optionLink}>Logout</Link>
                        <Link to="/PasswordReset" style={styles.optionLink}>Reset Password</Link>
                    </div>
                )}
            </div>
        </div>
    );
};

const styles = {
    container: {
        display: 'flex',
    },
    headerContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '10px 20px',
        backgroundColor: '#fff',
        width: 'calc(95% - 243px)',
        boxShadow: '0px 2px 5px rgba(0,0,0,0.1)',
        position: 'fixed',
        right: 0,
        top: 0,
        height: '60px',
        zIndex: 1000,
    },
    searchContainer: {
        flex: 1,
        marginRight: '20px',
    },
    searchInput: {
        width: '100%',
        padding: '10px',
        borderRadius: '20px',
        border: '1px solid #ddd',
        fontSize: '14px',
    },
    iconsContainer: {
        display: 'flex',
        alignItems: 'center',
        position: 'relative', // Tambahkan untuk positioning opsi
    },
    headerIcon: {
        margin: '0 10px',
        color: '#005694',
        cursor: 'pointer',
        fontSize: '18px',
    },
    adminText: {
        fontSize: '16px',
        fontWeight: 'bold',
        color: '#000',
    },
    optionsContainer: {
        position: 'absolute',
        right: '0',
        top: '60px', // Sesuaikan dengan tinggi header
        backgroundColor: '#fff',
        boxShadow: '0px 2px 5px rgba(0,0,0,0.1)',
        borderRadius: '8px',
        zIndex: 999, // Pastikan opsi di atas elemen lain
        padding: '10px',
    },
    optionLink: {
        display: 'block',
        padding: '8px 15px',
        color: '#005694',
        textDecoration: 'none',
        transition: 'background-color 0.3s',
    },
    sidebarContainer: {
        backgroundColor: '#fff',
        width: '250px',
        height: '100vh',
        padding: '20px 10px',
        boxShadow: '2px 0 5px rgba(0,0,0,0.1)',
        position: 'fixed',
        top: 0,
        left: 0,
    },
    logoContainer: {
        textAlign: 'center',
        marginBottom: '30px',
    },
    logoImage: {
        width: '120px',
        height: 'auto',
        margin: '0 auto',
        textAlign: 'center',
    },
    logoImageSmall: {
        width: '140px',
        height: 'auto',
        marginBottom: '10px',
    },
    sidebarList: {
        padding: 0,
        listStyle: 'none',
    },
    listSidebar: {
        margin: '20px 0',
    },
    link: {
        textDecoration: 'none',
        color: '#000',
        display: 'flex',
        alignItems: 'center',
        padding: '10px 15px',
        borderRadius: '8px',
        transition: 'background-color 0.3s',
    },
    linkActive: {
        backgroundColor: '#005694',
        color: '#fff',
    },
    icon: {
        marginRight: '10px',
    },
};

export default SidebarDashboard;
