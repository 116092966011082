import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { Editor } from '@tinymce/tinymce-react';
import configUrl from '../configUrl';
import SidebarDashboard from '../components/SidebarDashboard';
import ReloadButton from '../components/ReloadBtn';

const EditArticle = () => {
  const { id } = useParams();
  const [article, setArticle] = useState(null);
  const [categories, setCategories] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [body, setBody] = useState('');
  const [tags, setTags] = useState('');
  const [linkVideo, setLinkVideo] = useState('');
  const [showVideo, setShowVideo] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchArticleAndCategories = async () => {
      try {
        const articleResponse = await axios.get(`${configUrl.beBaseUrl}/api/articles/${id}`);
        setArticle(articleResponse.data);
        setBody(articleResponse.data.body || '');
        setTags(articleResponse.data.tags || '');
        setLinkVideo(articleResponse.data.linkVideo || ''); 
        
        if (articleResponse.data.image_url) {
          setImagePreview(`${configUrl.beBaseUrl}${articleResponse.data.image_url}`);
        }

        const categoriesResponse = await axios.get(`${configUrl.beBaseUrl}/api/categories`);
        setCategories(categoriesResponse.data);
      } catch (error) {
        console.error('Error fetching article or categories:', error);
      }
    };

    fetchArticleAndCategories();
  }, [id]);

  const handleEditorChange = (content) => {
    setBody(content);
  };

  const sanitizeHTML = (input) => {
    // Sanitasi HTML untuk menghapus tag berbahaya
    const parser = new DOMParser();
    const parsedDocument = parser.parseFromString(input, 'text/html');
    return parsedDocument.body.textContent || ''; // Mengambil hanya teks tanpa tag HTML
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setArticle(prevArticle => ({
      ...prevArticle,
      [name]: value
    }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const validateYouTubeUrl = (url) => {
    const regex = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+$/;
    return regex.test(url);
  };

  const handleCheckVideo = () => {
    if (validateYouTubeUrl(linkVideo)) {
      setShowVideo(true);
    } else {
      alert("Link video tidak valid. Pastikan link video YouTube benar.");
      setShowVideo(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const token = localStorage.getItem('authTokenSitusNews');

      if (!token) {
        navigate('/login');
        return;
      }

      const sanitizedBody = sanitizeHTML(body);
      let imageUrlToSave = article.image_url;

      if (selectedFile) {
        const formData = new FormData();
        formData.append('image', selectedFile);

        const uploadResponse = await axios.post(`${configUrl.beBaseUrl}/api/upload-image`, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
          },
        });

        imageUrlToSave = uploadResponse.data.image_url;
      }

      const updatedArticle = {
        ...article,
        image_url: imageUrlToSave,
        body: sanitizedBody,
        tags: tags,
        linkVideo: linkVideo,
      };

      await axios.put(`${configUrl.beBaseUrl}/api/articles/${id}`, updatedArticle, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      navigate(`/dashboard`);

    } catch (error) {
      console.error('Error updating article:', error.response?.data || error.message);
    }
  };

  if (!article) {
    return <div>Loading...</div>;
  }
 
  const handleCancel = () => {
    navigate(`/dashboard`);
  };

  return (
    <div style={{display: 'flex'}}>
            <div style={{width: '20%',}}><SidebarDashboard /></div>
    <div style={styles.container}>
      <h1 style={styles.header}>Edit Article</h1>
      <form onSubmit={handleSubmit} style={styles.form}>
        <div style={styles.inputContainer}>
          <label htmlFor="title" style={styles.label}>Title:</label>
          <input
            type="text"
            id="title"
            name="title"
            value={article.title}
            onChange={handleChange}
            placeholder="Enter article title"
            style={styles.input}
          />
        </div>

        <div style={styles.formGroup}>
          <label htmlFor="tags">Tags (Separate with commas):</label>
          <input
            type="text"
            id="tags"
            value={tags}
            onChange={(e) => setTags(e.target.value)}
            placeholder="Example: viral, trending, latest"
            style={styles.input}
          />
        </div>

        <div style={styles.fileUploadContainer}>
          <label style={styles.fileUploadBox}>
            <input
              type="file"
              name="image"
              id="image"
              onChange={handleFileChange}
            />
            {!imagePreview && <span style={styles.uploadIcon}>+</span>}
            {imagePreview && <div style={styles.wrapperChangeFoto}><span style={styles.uploadChangeFoto}>Change Photo?</span><div style={styles.uploadIcon}>+</div></div>}
          </label>
          {imagePreview && (
            <div style={styles.previewContainer}>
              <img src={imagePreview} alt="Preview" style={styles.previewImage} />
            </div>
          )}
        </div>
         {/* Input untuk Link Video */}
         <div style={styles.inputContainer}>
          <label htmlFor="linkVideo" style={styles.label}>Link Video YouTube:</label>
          <input
            type="text"
            id="linkVideo"
            name="linkVideo"
            value={linkVideo}
            onChange={(e) => setLinkVideo(e.target.value)}
            placeholder="Enter YouTube video link"
            style={styles.input}
          />
          <button type="button" onClick={handleCheckVideo} style={styles.checkButton}>Cek Video</button>
        </div>

        {/* Tampilkan Video jika valid */}
        {showVideo && (
          <div style={styles.videoContainer}>
            <iframe
              width="100%"
              height="315"
              src={`https://www.youtube.com/embed/${linkVideo.split('v=')[1]}`}
              title="YouTube video player"
              frameBorder="0"
              allowFullScreen
            ></iframe>
          </div>
        )}
        <div style={styles.tinymce}>
          <label htmlFor="body" style={styles.label}>Article Content:</label>
          <Editor
            apiKey='8s2do900sw0wp6yxt0glfn33as1qbuqzw29hvapk7wrjkslw'
            init={{
              toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
              tinycomments_mode: 'embedded',
              tinycomments_author: 'Author name',
              mergetags_list: [
                { value: 'First.Name', title: 'First Name' },
                { value: 'Email', title: 'Email' },
              ],
              ai_request: (request, respondWith) => respondWith.string(() => Promise.reject('See docs to implement AI Assistant')),
            }}
            value={body}
            onEditorChange={handleEditorChange}
            initialValue="Welcome to TinyMCE!"
          />
        </div>

        <div style={styles.inputContainer}>
          <label htmlFor="category" style={styles.label}>Category:</label>
          <select
            id="category"
            name="category_id"
            value={article.category_id}
            onChange={handleChange}
            style={styles.input}
          >
            {categories.map(category => (
              <option key={category.id} value={category.id}>
                {category.name}
              </option>
            ))}
          </select>
        </div>

        <div style={styles.buttonContainer}>
          <button type="submit" style={styles.saveButton}>Update Article</button>
          <button type="button" onClick={handleCancel} style={styles.cancelButton}>Cancel</button>
        </div>
      </form>
    </div>
    </div>
  );
};

const styles = {
  container: {
    maxWidth: '800px',
    margin: '100px',
    padding: '20px',
    border: '1px solid #ccc',
    borderRadius: '10px',
    backgroundColor: '#f9f9f9',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    textAlign: 'center',
    marginBottom: '20px',
    color: '#333',
  },
  formGroup: {
    marginBottom: '20px',
  },
  inputContainer: {
    marginBottom: '15px',
    width: '100%',
  },
  label: {
    display: 'block',
    marginBottom: '5px',
    color: '#333',
    fontWeight: 'bold',
  },
  tinymce: {
    marginBottom: '20px',
    fontSize: '17px',
    color: '#333',
    border: '1px solid #ddd',
    borderRadius: '4px',
    padding: '10px',
  },
  input: {
    width: '100%',
    padding: '10px',
    fontSize: '16px',
    border: '1px solid #ccc',
    borderRadius: '5px',
  },
  fileUploadContainer: {
    marginBottom: '20px',
  },
  fileUploadBox: {
    display: 'inline-block',
    padding: '10px',
    border: '1px dashed #ccc',
    borderRadius: '5px',
    cursor: 'pointer',
    textAlign: 'center',
    width: '100%',
  },
  previewContainer: {
    marginTop: '10px',
    textAlign: 'center',
  },
  previewImage: {
    maxWidth: '100%',
    maxHeight: '300px',
    borderRadius: '5px',
  },
  uploadIcon: {
    fontSize: '24px',
    color: '#333',
  },
  wrapperChangeFoto: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  uploadChangeFoto: {
    marginRight: '10px',
    color: '#333',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  saveButton: {
    padding: '10px 20px',
    fontSize: '16px',
    color: '#fff',
    backgroundColor: '#28a745',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  },
  checkButton: {
    marginTop: '10px',
    padding: '10px',
    backgroundColor: '#007bff',
    color: '#fff',
    borderRadius: '5px',
    border: 'none',
    cursor: 'pointer',
  },
  videoContainer: {
    marginTop: '20px',
    textAlign: 'center',
  },
  buttonContainer: {
    textAlign: 'center',
  },
  cancelButton: {
    padding: '10px 20px',
    fontSize: '16px',
    color: '#fff',
    backgroundColor: '#dc3545',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  },
};

export default EditArticle;
