import React from 'react';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import configUrl from '../configUrl';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../utils/AuthContext';

const GoogleLoginButton = () => {
    const navigate = useNavigate();
    const { login } = useAuth();

    const handleLoginSuccess = async (credentialResponse) => {
        try {
            const response = await axios.post(`${configUrl.beBaseUrl}/api/auth/google`, {
                token: credentialResponse.credential,
            });

            const { token, user } = response.data;

            sessionStorage.setItem('authTokenSitusNews', token);
            sessionStorage.setItem('authorId', JSON.stringify(user));

            localStorage.setItem('authTokenSitusNews', token);
            localStorage.setItem('authorId', JSON.stringify(user));

            login();
            navigate('/index');
        } catch (error) {
            console.error('Login Failed:', error);
        }
    };

    return (
        <GoogleOAuthProvider clientId="1002650215948-tu7itm4ho8pjft3jgivqmrp10187337g.apps.googleusercontent.com">
            <GoogleLogin
                onSuccess={handleLoginSuccess}
                onError={() => console.log('Login Failed')}
            />
        </GoogleOAuthProvider>
    );
};

export default GoogleLoginButton;
