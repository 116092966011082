import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Editor } from '@tinymce/tinymce-react';
import axios from 'axios';
import axiosInstance from '../utils/axios';
import configUrl from '../configUrl';
import SidebarDashboard from '../components/SidebarDashboard';

export default function CreateArticle() {
  const navigate = useNavigate();
  const [title, setTitle] = useState('');
  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState('');
  const [body, setBody] = useState('');
  const [authorId, setAuthorId] = useState('');
  const [categories, setCategories] = useState([]);
  const [newCategory, setNewCategory] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [tags, setTags] = useState('');
  const [linkVideo, setLinkVideo] = useState('');
  const [showVideo, setShowVideo] = useState(false);

  useEffect(() => {
    // Fetch categories from API
    axios.get(`${configUrl.beBaseUrl}/api/categories`)
      .then(response => {
        setCategories(response.data);
      })
      .catch(error => {
        console.error('There was an error fetching the categories!', error);
      });

      
  }, []);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setImage(file);
    if (file) {
      setImagePreview(URL.createObjectURL(file));
    }
  };

  const handleEditorChange = (content, editor) => {
    setBody(content);
  };
  
  const sanitizeHTML = (input) => {
    // Sanitasi HTML untuk menghapus tag berbahaya
    const parser = new DOMParser();
    const parsedDocument = parser.parseFromString(input, 'text/html');
    return parsedDocument.body.textContent || ''; // Mengambil hanya teks tanpa tag HTML
  };

  const validateYouTubeUrl = (url) => {
    const regex = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+$/;
    return regex.test(url);
  };

  const handleCheckVideo = () => {
    if (validateYouTubeUrl(linkVideo)) {
      setShowVideo(true);
    } else {
      alert("Link video tidak valid. Pastikan link video YouTube benar.");
      setShowVideo(false);
    }
  };

  // Fungsi untuk memformat judul agar setiap kata dimulai dengan huruf kapital
  const capitalizeTitle = (input) => {
    return input
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  const handleTitleChange = (e) => {
    const formattedTitle = capitalizeTitle(e.target.value);
    setTitle(formattedTitle);
    };

  const handleSubmit = async () => {
    const token = localStorage.getItem('authTokenSitusNews');
    const dataauthorId = localStorage.getItem('authorId');

    const author = JSON.parse(dataauthorId);

    // Akses nilai id
    const authorId = author.id;

    if (!token || !authorId) {
      alert("Anda harus login terlebih dahulu.");
      return;
    }
    
    if (!selectedCategory) {
      alert("Silakan pilih kategori.");
      return;
    }
  
    if (!title || !body) {
      alert("Judul dan konten artikel tidak boleh kosong.");
      return;
    }
  
    const formData = new FormData();
    formData.append('title', title);
    formData.append('body', sanitizeHTML(body));
    formData.append('author_id', authorId);
    formData.append('tags', tags); 
    formData.append('category_id', selectedCategory);
    formData.append('linkVideo', linkVideo);
    
    if (image) {
      formData.append('image', image);
    }
  
    try {
      const response = await axios.post(`${configUrl.beBaseUrl}/api/articles`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      });
  
      console.log('Article created successfully:', response.data);
      alert("Artikel berhasil dibuat!");
      // Reset form atau navigasi jika diperlukan
      navigate('/dashboard');
    } catch (error) {
      console.error('Error creating the article:', error.response?.data || error.message);
      alert("Ada kesalahan saat membuat artikel. Silakan coba lagi.");
    }
  };  

  return (
    <div style={{display: 'flex'}}>
            <div style={{width: '20%',}}><SidebarDashboard /></div>
    <div style={styles.container}>
      <form style={styles.form}>
        <h1 style={styles.header}>Create Article</h1>
        
        {/* Input untuk Title */}
        <div style={styles.inputContainer}>
          <label htmlFor="title" style={styles.label}>Title:</label>
          <input
            type="text"
            id="title"
            name="title"
            value={title}
            onChange={handleTitleChange}
            placeholder="Enter article title"
            style={styles.input}
          />
        </div>

        <div style={styles.formGroup}>
          <label htmlFor="tags">Tags (Pisahkan dengan koma):</label>
          <input
            type="text"
            id="tags"
            value={tags}
            onChange={(e) => setTags(e.target.value)}
            placeholder="Contoh: viral, trending, terkini"
            style={styles.input}
          />
        </div>

        <div style={{ marginBottom: "3px", fontWeight: "600" }}>Image / Photo:</div>
        <div style={styles.fileUploadContainer}>
          <label style={styles.fileUploadBox}>
            <div style={{display: 'none'}}>
            <input
              type="file"
              name="image"
              id="image"
              required
              onChange={handleFileChange}
            />
            </div>
            {!imagePreview && <span style={styles.uploadIcon}>+</span>}
            {imagePreview && <div style={styles.wrapperChangeFoto}><span style={styles.uploadChangeFoto}>Change Photo ?</span><div style={styles.uploadIcon}> + </div></div>}
          </label>
          {imagePreview && (
            <div style={styles.previewContainer}>
              <img src={imagePreview} alt="Preview" style={styles.previewImage} />
            </div>
          )}
        </div>
        <div>
        <div style={styles.inputContainer}>
          <label htmlFor="linkVideo" style={styles.label}>link video:</label>
          <input
            type="text"
            id="linkVideo"
            name="linkVideo"
            value={linkVideo}
            onChange={(e) => setLinkVideo(e.target.value)}
            placeholder="Enter url video youtube"
            style={styles.input}
          />
        </div>
        <button type="button" onClick={handleCheckVideo} style={styles.checkButton}>Cek Video</button>
        </div>
         {/* Tampilkan Video jika valid */}
         {showVideo && (
          <div style={styles.videoContainer}>
            <iframe
              width="220"
              height="150"
              src={`https://www.youtube.com/embed/${linkVideo.split('v=')[1]}`}
              title="YouTube video player"
              frameBorder="0"
              allowFullScreen
            ></iframe>
          </div>
        )}

        {/* Editor TinyMCE */}
        <div style={styles.tinymce}>
          <label htmlFor="body">Isi Artikel:</label>
        <Editor
          apiKey='8s2do900sw0wp6yxt0glfn33as1qbuqzw29hvapk7wrjkslw'
          init={{
            toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
            tinycomments_mode: 'embedded',
            tinycomments_author: 'Author name',
            mergetags_list: [
              { value: 'First.Name', title: 'First Name' },
              { value: 'Email', title: 'Email' },
            ],
            ai_request: (request, respondWith) => respondWith.string(() => Promise.reject('See docs to implement AI Assistant')),
          }}
          value={body}
          onEditorChange={handleEditorChange}
          initialValue="kartikalidya.com"
        />
        </div>

        {/* Dropdown Category */}
        <div style={styles.inputContainer}>
          <label htmlFor="category" style={styles.label}>Category:</label>
          <select
            id="category"
            name="category"
            value={selectedCategory}
            onChange={(e) => setSelectedCategory(e.target.value)}
            style={styles.input}
          >
            <option value="">Select a category</option>
            {categories.map((category) => (
              <option key={category.id} value={category.id}>
                {category.name}
              </option>
            ))}
          </select>
        </div>

        {/* Tombol Save dan Reset */}
        <div style={styles.buttonContainer}>
          <button type="button" onClick={handleSubmit} style={styles.saveButton}>Save</button>
        </div>
      </form>
    </div>
    </div>
  );
};
/* CSS Styles as JavaScript object */
const styles = {
  container: {
    maxWidth: '1000px',
    margin: '100px',
    padding: '10px',
    border: '1px solid #ccc',
    borderRadius: '10px',
    backgroundColor: '#f9f9f9',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    textAlign: 'center',
    marginBottom: '20px',
    color: '#333',
  },
  formGroup: {
    marginBottom: '20px',
  },
  inputContainer: {
    marginBottom: '15px',
  },
  label: {
    display: 'block',
    marginBottom: '5px',
    color: '#333',
    fontWeight: 'bold',
  },
  tinymce: {
    marginBottom: '20px',
    fontSize: '17px',
    color: '#333',
    fontWeight: 'bold',
  },
  input: {
    width: '100%',
    padding: '10px',
    fontSize: '16px',
    borderRadius: '5px',
    border: '1px solid #ccc',
    boxSizing: 'border-box',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '20px',
  },
  saveButton: {
    padding: '10px 20px',
    fontSize: '16px',
    borderRadius: '5px',
    border: 'none',
    cursor: 'pointer',
    backgroundColor: '#007bff',
    color: '#fff',
    width: '100%',
    marginTop: '-10px',
  },
  imagePreview: {
    marginTop: '10px',
    fontSize: '14px',
    color: '#333',
  },
  addButton: {
    padding: '8px 16px',
    fontSize: '14px',
    marginTop: '10px',
    backgroundColor: '#007bff',
    color: '#fff',
    borderRadius: '5px',
    cursor: 'pointer',
    border: 'none',
    width: '100%',
  },
  fileUploadContainer: {
    marginBottom: '20px',
    textAlign: 'center',
  },
  fileUploadBox: {
    display: 'inline-block',
    padding: '40px',
    border: '2px dashed #ccc',
    borderRadius: '10px',
    position: 'relative',
    cursor: 'pointer',
    textAlign: 'center',
  },
  uploadIcon: {
    fontSize: '30px',
    color: '#007bff',
    fontWeight: 'bold',
  },
  wrapperChangeFoto: {
    position: 'relative',
  },
  uploadChangeFoto: {
    position: 'absolute',
    top: '-20px',
    left: '50%',
    transform: 'translateX(-50%)',
    backgroundColor: '#007bff',
    color: 'white',
    padding: '5px 10px',
    borderRadius: '5px',
  },
  previewContainer: {
    marginTop: '15px',
    textAlign: 'center',
  },
  previewImage: {
    maxWidth: '100%',
    maxHeight: '200px',
    borderRadius: '10px',
  },
  checkButton: {
    marginTop: '10px',
    padding: '10px',
    backgroundColor: '#007bff',
    color: '#fff',
    borderRadius: '5px',
    border: 'none',
    cursor: 'pointer',
  },
  videoContainer: {
    marginTop: '20px',
    textAlign: 'center',
  },
  buttonContainer: {
    textAlign: 'center',
  },
};
