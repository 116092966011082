import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, Link } from 'react-router-dom';
import configUrl from '../configUrl';
import Header from '../components/Header';
import Footer from '../components/Footer';
import ShareButtons from '../components/ShareButtons';
import DOMPurify from 'dompurify'; 
import { height, width } from '@fortawesome/free-solid-svg-icons/fa0';

const ArticleDetail = () => {
  const { id } = useParams();
  const [article, setArticle] = useState(null);
  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState([]);
  const [relatedArticles, setRelatedArticles] = useState([]);
  const [comments, setComments] = useState([]); // State untuk komentar
  const [newComment, setNewComment] = useState({ name: '', email: '', body: '' }); // State untuk input komentar
  
  const [likeCount, setLikeCount] = useState(0);

  const handleLike = async (id) => {
    const token = localStorage.getItem('authTokenSitusNews');
    try {
      const response = await fetch(`${configUrl.beBaseUrl}/api/articles/${id}/like`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      
      if (response.ok) {
        const data = await response.json();
        setLikeCount(prevCount => prevCount + 1);
        console.log(data.message);
      } else {
        const errorData = await response.json();
        console.error(errorData.error);
      }      
    } catch (error) {
      console.error("Error liking the article:", error);
    }
  };

  const fetchLikeCount = async () => {
    try {
      const response = await axios.get(`${configUrl.beBaseUrl}/api/articles/${id}/likes`);
      setLikeCount(response.data.like_count);
    } catch (error) {
      console.error("Error fetching like count:", error);
    }
  };

  const fetchComments = async () => {
    try {
      const commentsResponse = await axios.get(`${configUrl.beBaseUrl}/api/articles/${id}/comments`);
      setComments(commentsResponse.data);
    } catch (err) {
      console.error("Error fetching comments:", err);
    }
  };

  useEffect(() => {
    const fetchArticle = async () => {
      try {
        const response = await axios.get(`${configUrl.beBaseUrl}/api/articles/${id}`);
        setArticle(response.data);

        // Set title halaman berdasarkan judul artikel
         document.title = response.data.title;
        
        // Fetch related articles
        const relatedResponse = await axios.get(`${configUrl.beBaseUrl}/api/articles/related/${response.data.category_id}/${id}`);
        setRelatedArticles(relatedResponse.data);

        setLoading(false);

      } catch (error) {
        console.error('Error fetching article or related articles:', error);
        setLoading(false);
      }
    };

    const fetchCategories = async () => {
      try {
        const response = await axios.get(`${configUrl.beBaseUrl}/api/categories`);
        setCategories(response.data);
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    fetchArticle();
    fetchCategories();
    fetchComments(); // Fetch comments whenever the component mounts
    fetchLikeCount();
    return () => {
      document.title = 'Portal Berita: kartikalidya.com'; // Ganti dengan judul default aplikasi Anda
    };
  }, [id]);

  const getCategoryName = (categoryId) => {
    const category = categories.find(cat => cat.id === categoryId);
    return category ? category.name : 'Unknown Category';
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
  
    return `Pada: ${day}-${month}-${year} / ${hours}:${minutes}`;
  };


  const handleCommentSubmit = async (e) => {
    e.preventDefault();
  
    // Sanitize input to prevent XSS
    const sanitizedComment = {
      name: DOMPurify.sanitize(newComment.name),
      email: DOMPurify.sanitize(newComment.email),
      body: DOMPurify.sanitize(newComment.body),
  };
  
  
    // Validate to ensure inputs are not empty after sanitization
    if (!sanitizedComment.name || !sanitizedComment.email || !sanitizedComment.body) return;
  
    try {
      const response = await axios.post(`${configUrl.beBaseUrl}/api/comments`, {
        article_id: id,
        name: sanitizedComment.name,
        email: sanitizedComment.email,
        body: sanitizedComment.body,
      });
  
      // Update state with the new comment
      setComments((prevComments) => [...prevComments, { ...sanitizedComment, id: response.data.id }]);
      setNewComment({ name: '', email: '', body: '' }); // Reset comment input
    } catch (error) {
      console.error('Error submitting comment:', error);
      // Optionally set an error state to display to the user
    }
  };
  
  if (loading) {
    const styles = {
        container: {
            width: '146px',
            height: '49px',
            position: 'absolute',
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
            margin: 'auto',
            opacity: 0,
            animation: 'fadeIn 1.15s forwards',
        },
        wrap: (delay) => ({
            position: 'absolute',
            animation: `translate 1150ms infinite ease-in-out alternate, zindex 2300ms infinite ease-in-out`,
            animationDelay: delay,
        }),
        ball: (color, delay) => ({
            width: '49px',
            height: '49px',
            boxShadow: '0 -6.25px 0 rgba(0,0,0,0.15) inset',
            backgroundColor: color,
            borderRadius: '50%',
            animation: 'scale 1150ms infinite ease-in-out alternate',
            animationDelay: delay,
            transform: 'scale(0.5)',
            border: '2px solid black',
        }),
        ballAfter: {
            content: '""',
            width: '49px',
            height: '13px',
            background: 'rgb(238,238,238)',
            position: 'absolute',
            top: '68px',
            borderRadius: '50%',
        },
        keyframes: `
            @keyframes translate {
                100% { transform: translateX(97px); }
            }
            @keyframes scale {
                100% { transform: scale(1); }
            }
            @keyframes zindex {
                25% { z-index: 1; }
                75% { z-index: -1; }
            }
            @keyframes fadeIn {
                100% { opacity: 1; }
            }
        `,
    };

    return (
        <div>
            <style>{styles.keyframes}</style>
            <div style={styles.container}>
                <div style={styles.wrap('0ms')}>
                    <div style={styles.ball('rgb(227,116,107)', '-575ms')}></div>
                </div>
                <div style={styles.wrap('-1150ms')}>
                    <div style={styles.ball('rgb(57,123,249)', '-1725ms')}></div>
                </div>
                <div style={styles.wrap('-1725ms')}>
                    <div style={styles.ball('rgb(244,180,0)', '-2300ms')}></div>
                </div>
                <div style={styles.wrap('-2875ms')}>
                    <div style={styles.ball('rgb(15,157,88)', '-3450ms')}></div>
                </div>
            </div>
        </div>
    );
}

  if (!article) {
    return <div>Article not found.</div>;
  }

  const imageUrl = `${configUrl.beBaseUrl}${article.image_url}`;

    // // Memecah artikel menjadi paragraf
    // const paragraphs = article.body.split('.').map((text, index) => (
    //   <p key={index} style={styles.articleBody}>{text.trim()}.</p>
    // ));

  return (
<div>
  <Header />
  <div style={styles.articleContainer}>
    <div style={styles.articleDetail}>
    <div style={styles.breadcrumb}>
  <Link to="/" style={styles.breadcrumbLink}>Home</Link> 
  <Link to={`/kategori/${getCategoryName(article.category_id)}`} style={styles.breadcrumbLink}> / {getCategoryName(article.category_id)}</Link> 
</div>
      {/* <div style={styles.categoryBtn}>{getCategoryName(article.category_id)}</div> */}
      <h1 style={styles.articleTitle}>{article.title}</h1>
         <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(article.content) }} />
<div style={styles.articleMetaContainer}>
  <p style={styles.articleDate}>{formatDate(article.created_at)}</p>
</div>
<hr style={{ borderTop: "2px solid #5555", margin: "10px 0" }} />  {/* Garis Underline */}
<div style={styles.articleMetaContainer}>
<p style={styles.articleMeta}>Penulis: {article.author_name}</p>
</div>
<div style={{ position: 'relative'}}>
<img src={imageUrl} alt={article.title} style={styles.articleImageFixed} /> {/* Gambar Terukur */}
<div style={styles.logoThumbnail}></div>
</div>
<span style={styles.articleBody}>
  {article.body.split('\n').map((paragraph, index) => (
    <p key={index}>{paragraph}</p>
))}
</span>
   <div>
  {article.linkVideo && (
    <div style={{ position: 'relative', width: '100%', paddingBottom: '56.25%', height: 0 }}>
      <iframe 
        src={`https://www.youtube.com/embed/${article.linkVideo.split('v=')[1]}`} 
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
        }} 
        frameBorder="0" 
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
        allowFullScreen
        title="YouTube Video"
      ></iframe>
    </div>
  )}
</div>

      <div style={styles.footerDetailArtikel}>
        <div style={{ display: "flex", alignItems: "center" }}>
        <div
  style={{
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    cursor: "pointer",
    width: "80px",
    height: "26px",
    overflow: "hidden",
    border: "0.1px solid #eee",
    paddingLeft: "10px",
    borderRadius: "5px",
    backgroundColor: "#005694",
    color: "#fff",
  }}
  onClick={() => handleLike(id)}
>
  Like
  <span style={{ marginLeft: "5px" }}>
    <i className="fas fa-thumbs-up"></i>
  </span>
</div>
          <div style={{ marginLeft: "5px" }}>{likeCount}</div>
        </div>
        {/* <p>{formatDate(article.created_at)}</p> */}
        <p> Dibaca: {article.views} Kali</p>
      </div>
      <hr style={{ borderTop: "2px solid #5555", margin: "10px 0" }} />  
      <ShareButtons articleTitle={article.title} articleUrl={`${configUrl.feBaseUrl}/articles/${article.id}/${article.slug}`} />
    </div>
 {/* Comment Section */}
 <div
      className="comments"
      style={{
        padding: "1%",
        border: "0.1px solid #ccc",
        borderRadius: "6px",
        width: "inherit",
        marginTop: "10px",
        textAlign: "center",
      }}
    >
      <div className="comments-section" style={{ marginBottom: "20px" }}>
        <h3>Komentar</h3>
        {comments.length > 0 ? (
          comments.map((comment, index) => (
            <div
              key={comment.id || index}
              className="comment"
              style={{ border: "0.1px solid #ddd", borderRadius: "8px", marginBottom: "20px" }}
            >
              <div
                style={{ padding: "10px", display: "flex", alignItems: "center", gap: "12px" }}
              >
                <img
                  src="https://cdn.iconscout.com/icon/free/png-256/free-batman-icon-download-in-svg-png-gif-file-formats--logo-dc-superhero-hero-justice-pack-avatars-icons-28695.png"
                  width="20px"
                  height="auto"
                  alt="User Avatar"
                />
                <strong>{comment.name}</strong>
              </div>
              <p
  style={{
    border: "0.1px solid #ddd",
    borderRadius: "8px",
    padding: "10px",
    margin: "0 20px 20px",
    backgroundColor: "#eee",
    textAlign: "left",
  }}
  dangerouslySetInnerHTML={{ __html: comment.body }}
></p>
        </div>
      ))
    ) : (
      <p style={{ color: "#666" }}>Belum ada komentar.</p>
    )}
  </div>

{/* Comment Form */}
<div
  className="comment-form-container"
  style={{
    padding: "20px",
    border: "1px solid #ccc",
    borderRadius: "6px",
    marginTop: "20px",
    backgroundColor: "#f9f9f9", // Optional: Light background for the container
    textAlign: "left",
  }}
>
  <h3 style={{ color: "#333", borderBottom: "2px solid #005694", display: "inline-block", paddingBottom: "5px", textAlign: "left", fontSize: "20px"}}>
    Tinggalkan Komentarmu
  </h3>
  <form onSubmit={handleCommentSubmit} style={{ marginTop: "15px" }}>
    <div style={{ marginBottom: "15px" }}>
      <label style={{ color: "#333", fontWeight: "bold", marginBottom: "5px", display: "block" }}>Nama :</label>
      <input
        type="text"
        value={newComment.name}
        onChange={(e) => setNewComment({ ...newComment, name: e.target.value })}
        required
        style={{
          width: "95%",
          padding: "10px",
          borderRadius: "5px",
          border: "1px solid #ccc",
          backgroundColor: "#fff",
          color: "#333",
          fontSize: "16px",
        }}
      />
    </div>
    <div style={{ marginBottom: "15px" }}>
      <label style={{ color: "#333", fontWeight: "bold", marginBottom: "5px", display: "block" }}>Email :</label>
      <input
        type="email"
        value={newComment.email}
        onChange={(e) => setNewComment({ ...newComment, email: e.target.value })}
        required
        style={{
          width: "95%",
          padding: "10px",
          borderRadius: "5px",
          border: "1px solid #ccc",
          backgroundColor: "#fff",
          color: "#333",
          fontSize: "16px",
        }}
      />
    </div>
    <div style={{ marginBottom: "20px" }}>
      <label style={{ color: "#333", fontWeight: "bold", marginBottom: "5px", display: "block" }}>Komentar:</label>
      <textarea
        value={newComment.body}
        onChange={(e) => setNewComment({ ...newComment, body: e.target.value })}
        rows={5}
        required
        style={{
          width: "95%",
          padding: "10px",
          borderRadius: "5px",
          border: "1px solid #ccc",
          backgroundColor: "#fff",
          color: "#333",
          fontSize: "16px",
          resize: "vertical",
        }}
      ></textarea>
    </div>
    <button
      type="submit"
      style={{
        width: '98%',
        padding: "10px 20px",
        backgroundColor: "#005694",
        color: "#fff",
        borderRadius: "5px",
        border: "none",
        cursor: "pointer",
        fontSize: "16px",
      }}
    >
      Kirim Komentar
    </button>
  </form>
</div>
</div>


    {/* Related Articles */}
    <div style={styles.relatedArticles}>
      <div style={styles.titleContainer}>
        <div style={styles.underline}></div>
        <h3 style={styles.relatedArticlesHeader}>Artikel Terkait</h3>
      </div>
      {relatedArticles.length > 0 ? (
        relatedArticles.map((relatedArticle) => (
          <div key={relatedArticle.id} style={styles.relatedArticleCard}>
            <img
              src={`${configUrl.beBaseUrl}${relatedArticle.image_url}`}
              alt={relatedArticle.title}
              style={styles.relatedArticleImage}
            />
            <div style={{ textAlign: 'left' }}>
            <div>
              <Link
                to={`/articles/${relatedArticle.id}/${encodeURIComponent(
                  relatedArticle.slug
                )}`}
                style={styles.relatedArticleTitle}
              >
                {relatedArticle.title.substring(0, 60)}...
              </Link>
              <p style={styles.relatedArticleBody}>
                {relatedArticle.body.substring(0, 80)}...
              </p>
              <span style={styles.relatedArticleMeta}>
                {formatDate(relatedArticle.created_at)}
              </span>{" "}
              -{" "}
              <span style={styles.relatedArticleViews}>
                Dibaca: {relatedArticle.views} kali
              </span>
            </div>
          </div>
          </div>
        ))
      ) : (
        <p>Tidak ada artikel terkait.</p>
      )}
    </div>
  </div>
  <Footer />
</div>
  )
};

const styles = {
  articleContainer: {
    maxWidth: '800px',
    margin: '0 auto',
    padding: '20px',
    fontFamily: "'Arial', sans-serif",
    backgroundColor: '#f9f9f9',
    borderRadius: '8px',
    boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
  },
  breadcrumb: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '14px',
    color: '#666',
    marginBottom: '10px',
  },
  breadcrumbLink: {
    textDecoration: 'none',
    color: '#005694',
    marginRight: '5px',
  },
  articleDetail: {
    borderBottom: '1px solid #ddd',
    paddingBottom: '20px',
    marginBottom: '20px',
    backgroundColor: '#fff',
    borderRadius: '5px',
    padding: '15px',
  },
  categoryBtn: {
    display: 'inline-block',
    padding: '5px 10px',
    backgroundColor: '#005694',
    color: '#fff',
    borderRadius: '5px',
    textTransform: 'uppercase',
    fontSize: '12px',
    marginBottom: '10px',
  },
  articleTitle: {
    fontSize: "28px",
    fontWeight: "bold",
    marginBottom: "20px",
    textAlign: 'left',
  },
  articleMetaContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "12px",
    color: "#555",
  },
  articleMeta: {
    margin: "0",
  },
  articleDate: {
    margin: "0",
  },
  articleImageFixed: {
    width: "100%",
    height: "auto", // Menjaga proporsi gambar agar tidak berubah
    maxHeight: "350px", // Batas maksimal tinggi gambar yang disamakan
    objectFit: "cover", // Memastikan gambar tidak terdistorsi
    marginBottom: "20px",
    borderRadius: '10px',
  },
  logoThumbnail: {
    position: 'absolute',
    backgroundImage: 'url("https://www.kartikalidya.com/image/kartikanews-logo.png")',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    opacity: '0.7',
    width: '24%',
    height: '16%',
    top: '1%',
    left: '1.4%',
},
  articleBody: {
    fontSize: '18px',
    lineHeight: '1.6',
    color: '#333',
    marginBottom: '20px',
    textAlign: 'justify', // Menambahkan rata kanan-kiri
  },
  footerDetailArtikel: {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '14px',
    color: '#888',
  },
  relatedArticles: {
    marginTop: '40px',
    paddingTop: '20px',
    borderTop: '1px solid #ddd',
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '20px',
  },
  underline: {
    width: '6px',
    height: '24px',
    backgroundColor: '#005694',
    marginRight: '10px',
  },
  relatedArticlesHeader: {
    fontSize: '24px',
    fontWeight: 'bold',
    marginBottom: '15px',
  },
  relatedArticleCard: {
    display: 'flex',
    marginBottom: '20px',
    borderBottom: '1px solid #ddd',
    paddingBottom: '15px',
    paddingTop: '15px',
  },
  relatedArticleImage: {
    width: '100px',
    height: '80px',
    objectFit: 'cover',
    marginRight: '15px',
    borderRadius: '4px',
    boxShadow: '0 1px 5px rgba(0,0,0,0.1)',
  },
  relatedArticleTitle: {
    fontSize: '14px',
    fontWeight: 'bold',
    color: '#005694',
    textDecoration: 'none',
    textAlign: 'left',
  },
  relatedArticleBody: {
    fontSize: '12px',
    color: '#333',
    margin: '5px 0',
    textAlign: 'left',
  },
  relatedArticleMeta: {
    fontSize: '12px',
    color: '#666',
    textAlign: 'left',
  },
  relatedArticleViews: {
    fontWeight: 'bold',
    fontSize: '12px',
    color: '#666',
    textAlign: 'right',
  },
};

export default ArticleDetail;
