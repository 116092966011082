import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import configUrl from '../configUrl';
import Pagination from '../components/Pagination';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faList } from '@fortawesome/free-solid-svg-icons';
import SidebarDashboard from './SidebarDashboard';

const ArticleList = () => {
  const [articles, setArticles] = useState([]);
  const [categories, setCategories] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const articlesPerPage = 8;
  const navigate = useNavigate();

  useEffect(() => {
    const fetchArticles = async () => {
      const token = localStorage.getItem('authTokenSitusNews');

      if (!token) {
        navigate('/login');
      } else {
        try {
          const response = await axios.get(`${configUrl.beBaseUrl}/api/articles`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          setArticles(response.data);
        } catch (error) {
          console.error('Error fetching articles:', error.response?.data || error.message);
        }
      }
    };

    fetchArticles();

    axios.get(`${configUrl.beBaseUrl}/api/categories`)
      .then(response => {
        setCategories(response.data);
      })
      .catch(error => {
        console.error('Error fetching categories:', error);
      });
  }, [navigate]);

  const getCategoryName = (categoryId) => {
    const category = categories.find(cat => cat.id === categoryId);
    return category ? category.name : 'Tidak ada Category';
  };

  const handleEdit = (articleId) => {
    navigate(`/edit-article/${articleId}`);
  };

  const handleDelete = async (articleId) => {
    if (window.confirm('Yakin akan menghapus artikel ini ?')) {
      try {
        const token = localStorage.getItem('authTokenSitusNews');
        if (!token) {
          navigate('/login');
          return;
        }

        await axios.delete(`${configUrl.beBaseUrl}/api/articles/${articleId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        console.log(`Article with id ${articleId} deleted`);
        setArticles(articles.filter(article => article.id !== articleId));
      } catch (error) {
        console.error('Error deleting article:', error);
      }
    }
  };

  const handleSetHeadline = async (articleId) => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        navigate('/login');
        return;
      }

      await axios.post(`${configUrl.beBaseUrl}/api/articles/${articleId}/headline`, {}, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      console.log(`Article with id ${articleId} set as headline`);
      alert('Artikel berhasil dijadikan headline');
    } catch (error) {
      console.error('Error setting article as headline:', error);
    }
  };

  const indexOfLastArticle = currentPage * articlesPerPage;
  const indexOfFirstArticle = indexOfLastArticle - articlesPerPage;
  const currentArticles = articles.slice(indexOfFirstArticle, indexOfLastArticle);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>

      <div style={{ display: 'flex', flex: 1 }}> {/* Use flex to allow for proper sizing */}
        <div style={{ width: '20%' }}><SidebarDashboard /></div>
        <div style={styles.articleList}>
          <h2 style={styles.title}>
            <FontAwesomeIcon icon={faList} style={styles.icon} /> List Article
          </h2>
          <table style={styles.table}>
            <thead>
              <tr>
                <th style={styles.th}>No</th>
                <th style={styles.th}>Image</th>
                <th style={styles.th}>Title</th>
                <th style={styles.th}>Category</th>
                <th style={styles.th}>Date</th>
              </tr>
            </thead>
            <tbody>
              {currentArticles.map((article, index) => (
                <tr key={article.id}>
                  <td style={styles.td}>{index + 1}</td>
                  <td style={styles.td}>
                    <img
                      src={`${configUrl.beBaseUrl}${article.image_url}`}
                      alt={article.title}
                      style={styles.img}
                    />
                  </td>
                  <td style={styles.td}>
                    <Link to={`/articles/${article.id}/${encodeURIComponent(article.slug)}`} style={styles.articleTitle}>
                      {article.title}
                    </Link>
                  </td>
                  <td style={styles.td}>{getCategoryName(article.category_id)}</td>
                  <td style={styles.td}>
                    {new Date(article.created_at).toLocaleDateString('id-ID', {
                      weekday: 'long',
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric',
                    })}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <Pagination 
            articlesPerPage={articlesPerPage} 
            totalArticles={articles.length} 
            paginate={paginate} 
            currentPage={currentPage} 
          />
        </div>
      </div>
    </div>
  );
};

const styles = {
  articleList: {
    padding: '20px',
    flex: 1, // Allow this to grow
    overflowY: 'auto', // Add scrolling if needed
    margin: '70px',
    width: '100%',
  },
  title: {
    fontSize: '24px',
    fontWeight: 'bold',
    marginBottom: '20px',
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
    marginBottom: '20px',
  },
  th: {
    padding: '10px',
    textAlign: 'left',
    backgroundColor: '#f4f4f4',
    borderBottom: '2px solid #ddd',
  },
  td: {
    padding: '10px',
    textAlign: 'left',
    borderBottom: '1px solid #ddd',
  },
  img: {
    width: '80px',
    height: '60px',
    objectFit: 'cover',
    borderRadius: '5px',
  },
  icon: {
    fontSize: '20px',
  },
  articleTitle: {
    fontSize: '14px',
    fontWeight: 'bold',
    color: '#007BFF',
    textDecoration: 'none',
  },
};

export default ArticleList;
