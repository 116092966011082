import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import configUrl from '../configUrl';

const TopTagsLink = () => {
  const [articles, setArticles] = useState([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 480);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 480);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const appliedStyles = isMobile ? mobileStyles : styles;

  useEffect(() => {
    const fetchTopTagArticles = async () => {
      try {
        const response = await axios.get(`${configUrl.beBaseUrl}/api/articlestoptags`);
        setArticles(response.data);
      } catch (error) {
        console.error('Error fetching top tag articles:', error);
      }
    };

    fetchTopTagArticles();
  }, []);

  const parseTags = (tagsString) => {
    return tagsString.split(',').map(tag => tag.trim());
  };

  return (
    <div style={appliedStyles.topTagLink}>
      <h4 style={appliedStyles.header}>#TRENDING</h4>
      <div style={appliedStyles.topTagLinkList}>
        {articles.map(article => (
          <div key={article.id} style={appliedStyles.cardTagLink}>
            <span>
              {parseTags(article.tags).map((tag, index) => (
                <span key={index} style={appliedStyles.linkTag}>
                  <Link to={`/tags/${tag.replace(/\s+/g, '-').toLowerCase()}`} style={appliedStyles.linkText}>
                    #{tag}
                  </Link>
                </span>
              ))}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

const styles = {
  topTagLink: {
    backgroundColor: '#ffffff',
    padding: '10px',
    marginRight: '90px', // Center container on mobile
    marginLeft: '-80px', // Center container on mobile
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    marginBottom: '30px',
    marginTop: '50px',
    maxWidth: '700px', // Limit width for better readability
  },
  header: {
    fontSize: '1.5rem',
    marginBottom: '12px',
    color: '#005694',
    textAlign: 'center',
  },
  topTagLinkList: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center', // Center content in a row
    gap: '10px',
    marginBottom: '30px',
  },
  cardTagLink: {
    backgroundColor: '#005694',
    color: 'white',
    padding: '10px 15px',
    borderRadius: '5px',
    transition: 'background-color 0.3s, transform 0.3s',
    cursor: 'pointer',
    display: 'flex', // Flex to center content within the card
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center', // Center text within the card
  },
  linkTag: {
    textDecoration: 'none',
  },
  linkText: {
    color: 'white',
    fontWeight: '500',
    textDecoration: 'none',
  },
};

// Mobile Styles
const mobileStyles = {
  topTagLink: {
    backgroundColor: '#ffffff',
    padding: '10px',
    margin: '0 auto', // Center container on mobile
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    marginBottom: '20px',
    marginTop: '20px',
    maxWidth: '600px', // Limit width for better readability
  },
  header: {
    fontSize: '1.5rem',
    marginBottom: '15px',
    color: '#005694',
    textAlign: 'center',
  },
  topTagLinkList: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center', // Center content in a row
    gap: '10px',
  },
  cardTagLink: {
    backgroundColor: '#005694',
    color: 'white',
    padding: '10px 15px',
    borderRadius: '5px',
    transition: 'background-color 0.3s, transform 0.3s',
    cursor: 'pointer',
    display: 'flex', // Flex to center content within the card
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center', // Center text within the card
  },
  linkTag: {
    textDecoration: 'none',
  },
  linkText: {
    color: 'white',
    fontWeight: '500',
    textDecoration: 'none',
  },
};

export default TopTagsLink;
